.service-time-form {
    max-width: 700px;
    margin: 100px auto;
    padding: 30px;
    border-radius: 30px;
    background-color: transparent;
    box-shadow: 0 0 10px #9b9ac573;
    color: #000;
}

.service-time-form h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #000;
}

.service-time-form h3 {
    font-size: 18px;
    margin-bottom: 1px;
}

.service-time-form form {
    margin-bottom: 20px;
}

.service-time-form .time-group {
    margin-bottom: 15px;
    width: 100%;
}

.startEndTime {
    gap: 20px;
}

.service-time-form label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #5a4b81;
}

.service-time-form input[type="time"],
.service-time-form input[type="number"] {
    width: calc(100% - 20px);
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.service-time-form button {
    background-color: #50497F;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 3px;
}

.service-time-form button:hover {
    background-color: #352f5c;
}

.service-time-form ul {
    list-style-type: none;
    padding: 0;

}

.service-time-form ul li {
    margin-bottom: 10px;
    color: #fff;
}

/* serviceTime.css */

/* Styles for select dropdown */
.select-dropdown {
    width: 98%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

/* Optional: Style for option items */
.select-dropdown option {
    font-size: 1rem;
    background-color: #fff;
    color: #333;
}

.day-radios {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.day-label {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #5a4b81;
    border-radius: 5px;
    background-color: white;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.day-label.selected {
    background-color: #5a4b81;
    color: white;
}

.day-label input[type="radio"] {
    display: none;
}

.day-label.disabled {
    cursor: not-allowed;
}

.serviceTimeMain {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.service-time-form button {
    margin-top: 30px;
}

.react-datepicker-popper .react-datepicker__day-name, .react-datepicker-popper .react-datepicker__day, .react-datepicker-popper .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
    font-size: 14px;
    border-radius: 30px;
}

.service-time-form .react-datepicker-popper {
    width: 100%;
    max-width: 300px !important;
    box-shadow: 0 0 10px #9b9ac573;
}

.service-time-form .react-datepicker-popper h2 {
    font-size: 15px;
}

.service-time-form .react-datepicker__navigation {
    margin-top: 0;
    background-color: #fff;
}

.react-datepicker__close-icon {
    margin-top: 0 !important;
}

.service-time-form input {
    width: 100% !important;
}

.price-group, .holiday-group {
    margin-bottom: 15px;
}

.extras-checkbox input[type="checkbox"] {
    width: unset !important;
}

@media screen and (max-width: 992px) {
    .service-details .service-time-form {
        padding: 20px 20px !important;
    }
}


@media screen and (max-width: 600px) {
    .day-radios {
        margin-bottom: 20px;
        gap: 5px;
    }
    .startEndTime {
        flex-direction: column;
        gap: 0;
    }
    .day-label {
        padding: 5px 15px;
    }
    .service-time-form button {
        margin-top: 20px;
    }

}