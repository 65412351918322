@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
*{
  box-sizing: border-box;
}

/* styles.css */

/* Default Light Mode */
:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --primary-color: #007bff;
  --button-bg: #50497F;
  --button-text: #ffffff;
}



body {
  margin: 0;
  font-family: "Jost", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-fluid{
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.buttonDiv{
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}


/* topbar loading */

.topbar-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #7368b4;
  z-index: 9999;
  animation: loading-animation 2s linear infinite;
}

button:focus{
  outline: none !important; 
}

.userLayout {
  display: flex;
  width: 100%;
  /* max-width: 1420px; */
  margin: 0 auto;
}

main {
  width: 100%;
}

.btn {
  border-radius: 7px !important;
}


.btn-primary {
  background-color: #50497F !important;
  border-color: #50497F !important;
  color: #fff !important;
  border: none !important;
  border-radius: 7px !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #3f3963 !important;
  border-color: #3f3963 !important;
}

.btn-primary:focus {
  color: #fff !important;
  background-color: #3f3963 !important;
  border-color: #3f3963 !important;
  box-shadow: 0 0 0 .2rem #615797 !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #3f3963 !important;
  border-color: #3f3963 !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #615797 !important;
}
@keyframes loading-animation {
  0% {
      transform: translateX(-100%);
  }
  100% {
      transform: translateX(100%);
  }
}