.mainSignup {
  width: 100%;
  display: flex;
  min-height: 100vh;
  background-image: url('../../../public/login3.jpg');
  background-size: cover;
}

.signupLeft {
  width: 100%;
  max-width: 50%;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
}

.signupLeft .signUpLink {
  display: grid;
}

.signupLeft .signUpLink a {
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
  text-decoration: none;
}

.signupContent {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.signupContent label {
  color: #fff !important;
}

.form-container {
  width: 100%;
  max-width: 50%;
  min-height: 100vh;
  padding: 20px;
  background: #0000008a;
  backdrop-filter: blur(5px);
  color: #fff;
  box-shadow: 0 0 10px #9b9ac573;
}

.flexField {
  display: flex;
  gap: 10px;
}

.form-container h2 {
  font-size: 24px;
  /* color: #fff; */
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  /* padding: 10px; */
  height: 43px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.password-input {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
}

.submit-btn {
  background-color: #8472ff;
  width: 100%;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #715cf8;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 12px;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-size: 14px;
}


@media screen and (max-width: 576px) {
  .form-container {
    max-width: 100%;
  }

  .signupLeft {
    display: none;
  }
}