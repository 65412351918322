.service-form {
    padding: 30px;
    border-radius: 30px;
    background-color: transparent;
    box-shadow: 0 0 10px #9b9ac573;
    max-width: 700px;
    margin: 100px auto;
}

.service-form h2 {
    color: #000;
}

.form-group {
    margin-bottom: 15px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #50497F;
}

input[type="text"],
input[type="date"],
input[type="time"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    outline: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input[type="text"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
textarea:focus {
    border-color: #50497F;
    box-shadow: 0 2px 6px 0 rgba(115,103,240,.3);
}

.form-group input {
    outline: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-group input:focus{
    border-color: #50497F;
    box-shadow: 0 2px 6px 0 rgba(115,103,240,.3);
}

.css-t3ipsp-control {
    border-color: #50497F !important;
}
textarea {
    resize: vertical;
}

.btn-create-service {
    background-color: #50497F;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.btn-create-service:hover {
    background-color: #352f5c;
}

.form-text {
    color: #000;
}

.imageUpload {
    color: #000;
    padding: 6px 10px !important;
}

input#react-select-5-input {
    height: 0;
}

img.preview-image {
    width: 160px;
    margin-right: 15px;
    height: 100px;
    border-radius: 8px;
    border: 2px solid #000;
    margin-top: 10px;
    object-fit: fill;
}

input#react-select-3-input {
    height: 0px;
}

.image-upload-wrapper {
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px dashed #50497F;
}

.image-upload-wrapper:hover {
    background-color: #e7e7e7;
}

.image-upload-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.image-upload-wrapper input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
}

.upload-text {
    color: #50497F;
    pointer-events: none;
}

.css-hlgwow {
	height: 40px;
}

.css-1jqq78o-placeholder {
    height: 40px;
    align-items: center;
    display: flex;
}

.css-1nmdiq5-menu{
    z-index: 99999999999 !important;
}
@media screen and (max-width: 600px) {
    .image-upload-wrapper {
        width: 120px;
        height: 120px;
    }

    .form-group {
        margin-bottom: 10px;
    }
}