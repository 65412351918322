.mainLogin {
  width: 100%;
  display: flex;
  min-height: 100vh;
  background-image: url('../../../public/login3.jpg');
  background-size: cover;
}

.loginLeft {
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.loginLeft .signUpLink a {
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
  text-decoration: none;
}

.loginLogo {
  display: flex;
  align-items: center;
  color: #fff;
  gap: 5px;
}

.loginLeft .signUpLink {
  color: #fff;
  display: grid;
}

.loginLogo img {
  width: 30px;
  height: 30px;
}

.loginLogo h2 {
  line-height: normal;
  margin-bottom: 0;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: var(--background-color); */
  box-shadow: 0 0 10px #9b9ac573;
  padding: 40px 35px;
  min-height: 100vh;
  width: 100%;
  max-width: 50%;
  background: #0000008a;
  backdrop-filter: blur(5px);
  color: #fff;
}

.login-container label {
  color: #fff !important;
}

.loginCotent {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
}

.login-container h2 {
  /* margin-bottom: 20px; */
  /* color: #000; */
  font-size: 28px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #50497F;
}

.form-group input {
  width: 100%;
  height: 43px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 10px;
}

.error-message {
  margin-top: 10px;
  padding: 10px;
  background-color: #ffe6e6;
  border: 1px solid #f5c6cb;
  border-radius: 3px;
  color: #721c24;
}

.login-container button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #8472ff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}

.login-container button:hover {
  background-color: #715cf8;
}

.login-container button:active {
  background-color: #715cf8;
  transform: translateY(1px);
}

.password-input-container {
  position: relative;
}

button.toggle-password {
  position: absolute;
  top: 0;
  margin-top: 0;
  right: 0;
  width: 40px;
  /* padding: 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.signUpLink {
  margin-top: 15px;
  margin-bottom: 0;
  /* color: #fff; */
}

.signUpLink a {
  color: #50497F;
  text-decoration: underline;
}

.password-input input,
#password {
  font-family: sans-serif;
}

.rightLink {
  display: none;
}

.rightLink a {
  color: #fff;
}

@media screen and (max-width: 576px) {
  .login-container {
    padding: 30px 20px;
    max-width: 100%;
  }

  .loginLeft {
    display: none;
  }

  .rightLink {
    display: block;
  }

  .login-container h2 {
    font-size: 24px;
  }
}